<!--eslint-disable-->

<template>
  <div class="d-flex">
    <div class="mr-25">
      <b-form-spinbutton
        v-model="product.amount"
        min="1"
        :max="product.shopProduct.shopProductStoragesSum"
        size="sm"
        vertical
      />
    </div>
    <b-card class="checkout-product mb-0 p-0 flex-grow-1">
      <div>
        <div
          class="position-relative d-flex align-content-center justify-content-between productBody flex-wrap flex-sm-nowrap ml-75"
          style="height: 71px"
        >
          <!--    Cart Items Product title    -->
          <div class="d-flex align-items-center productContainer w-100 text-nowrap">
            <b-badge
              v-if="product.shopProduct.shopProductDiscountsActive.length"
              v-b-tooltip
              variant="light-success"
              class="ml-25"
              :title="$t('ProductOnDiscount')"
            >
              <feather-icon
                icon="DollarSignIcon"
                size="20"
              />
            </b-badge>
            <div class="font-weight-bold ml-50">
              <span>
                <router-link
                  v-b-tooltip
                  :to="{ name: 'productView', params: { productId: product.shopProduct.id } }"
                  :title="product.shopProduct.translations[0].name"
                >
                  {{ product.shopProduct.translations[0].name | textIsland(38) }}
                </router-link>
              </span>
            </div>
          </div>

          <!--    Cart Items Amount Selected    -->
          <div class="d-flex position-absolute position-top-0 position-right-0 h-100">
            <div
              class="d-flex flex-column align-items-end justify-content-center productContainer pl-50 fade-left bg-white"
            >
              <!--    Cart Items Product Delete    -->
              <div class="d-flex">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  class="btn-wishlist btn-icon rounded-circle"
                  @click="$emit('remove', product)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="btn-wishlist btn-icon rounded-circle mx-50"
                  @click="showDiscount = !showDiscount"
                >
                  <feather-icon icon="MoreVerticalIcon" />
                </b-button>
              </div>
            </div>
            <div
              v-b-tooltip.html="{ customClass: 'product-tooltip' }"
              class="d-flex flex-column align-items-center justify-content-center productContainer w-100 px-1 bg-primary text-nowrap"
              style="min-width: 200px; border-radius: 5px"
              :title="pricesTooltip"
            >
              <span class=" productLabel text-light">{{ $t('offer.product.priceGross') }}</span>
              <!--    Cart Items Product price    -->
              <h5 class="text-light font-weight-bolder">
                {{ grossPrice(product) | priceFormat }} {{ settings.defaultCurrency || 'PLN' }}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <!-- discounts -->
      <transition name="fade">
        <div
          v-show="showDiscount"
          class="px-1 mb-1"
        >
          <div
            class="divider mb-1 mt-2 font-weight-bold text-primary"
          >
            <div class="divider-text px-1 text-capitalize">
              {{ $t('VatSettings') }}
            </div>
          </div>

          <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-lg-between">
            <div
              style="min-width: 275px"
              class="mb-50 mb-lg-0"
            >
              {{ $t('SelectVatValueOfProduct') }}
            </div>

            <sw-select>
              <v-select
                v-model="productVat"
                v-b-tooltip.hover
                :options="['0', '8', '23']"
                :disabled="isOnOverwriteVatInCart"
                :clearable="false"
                :title="$t(isOnOverwriteVatInCart ? 'VatValueIsSetGlobally' : 'VatValueOfProduct')"
              />
            </sw-select>
          </div>

          <div
            class="divider mb-1 mt-md-50 font-weight-bold text-primary"
          >
            <div class="divider-text px-1 text-capitalize">
              {{ $t('Offer.Discount') }}
            </div>
          </div>

          <!-- Product Global Discount -->
          <div
            v-if="product.shopProduct.shopProductDiscounts && product.shopProduct.shopProductDiscounts.length"
            class="d-flex align-items-center flex-wrap my-50"
          >
            <div style="min-width: 275px">
              {{ $t('Offer.CatalogDiscount') }}
            </div>
            <div>
              <b-form-checkbox
                :checked="product.shopProduct.shopProductDiscounts[0].type !== 'NETTO_PERCENT'"
                class="ml-25"
                name="check-button"
                variant="primary"
                disabled
                switch
                inline
              >
                <span class="switch-icon-left">
                  <feather-icon icon="DollarSignIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="PercentIcon" />
                </span>
              </b-form-checkbox>
            </div>
            <div class="flex-grow-1">
              <cleave
                v-model="product.shopProduct.shopProductDiscounts[0].value"
                :options="cleaveOptions.number"
                disabled
                class="form-control form-control-sm"
              />
            </div>
          </div>
          <!-- Product Additional Discount -->
          <div
            v-if="checkPermissions([$roles.CONTACT_THREAD_CART_DISCOUNT_EDIT])"
          >
            <b-alert
              show
              variant="primary"
              class="p-25"
            >
              <div class="alert-body">
                {{ $t('DiscountInfoBox') }}
              </div>
            </b-alert>
            <div class="d-flex align-items-center justify-content-between flex-wrap my-50">
              <div style="min-width: 275px">
                {{ $t('Offer.AdditionalDiscount') }}
              </div>
              <div class="d-inline-flex flex-wrap align-items-center">
                <b-form-checkbox
                  v-model="discount.discountType"
                  value="NETTO_VALUE"
                  unchecked-value="NETTO_PERCENT"
                  class="ml-25"
                  name="check-button"
                  switch
                  inline
                  @change="changeSwitch"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="DollarSignIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="PercentIcon" />
                  </span>
                </b-form-checkbox>

                <div class="flex-grow-1">
                  <cleave
                    v-model="discount.discount"
                    :options="cleaveOptions.number"
                    class="form-control form-control-sm my-50 my-md-25"
                    @blur="$emit('update-discount', discount)"
                    @input="changePrice"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- User Discount -->
          <div>
            <div class="d-flex align-items-center justify-content-between flex-wrap my-50">
              <div style="min-width: 275px">
                {{ $t('UserDiscount') }}

                <b-button
                  v-b-tooltip.focus.v-primary
                  variant="flat-primary"
                  :title="$t('')"
                  class="btn-icon p-25"
                  size="sm"
                >
                  <feather-icon icon="InfoIcon" />
                </b-button>
              </div>
              <div class="d-inline-flex flex-wrap align-items-center">
                <div class="flex-grow-1">
                  <cleave
                    :options="cleaveOptions.number"
                    class="form-control form-control-sm my-50 my-md-25"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </b-card>
  </div>
</template>

<script>
import { BFormSpinbutton, VBTooltip } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import checkRanks from '@/helpers/checkRanks'
import vSelect from 'vue-select'
import { grossPrice, netPriceAfterCartDiscount } from '@/helpers/cartProductPrices'
import { mapGetters } from 'vuex'

export default {
  components: {
    // BImg,
    BFormSpinbutton,
    Cleave,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    gross: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      required: true,
    },
    isOnOverwriteVatInCart: {
      type: Boolean,
      required: false,
      default: false,
    },
    overwriteVatInCart: {
      required: false,
      default: 0,
    },
  },
  emits: ['remove', 'changeQuantity', 'updateVat'],
  setup() {
    const cleaveOptions = {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }

    return {
      cleaveOptions,
    }
  },
  computed: {
    ...mapGetters({
      settings: 'system/getSettings',
    }),
  },
  data: () => ({
    showDiscount: false,
    discount: {
      discountType: '',
      discount: 0,
    },
    productVat: 0,
  }),
  watch: {
    overwriteVatInCart(newValue) {
      this.assignOverwriteVatInCart(newValue)
    },
    productVat(newValue, oldValue) {
      // eslint-disable-next-line
      if (newValue != oldValue) this.$emit('update-vat', newValue)
    },
  },
  mounted() {
    this.discount = {
      discount: this.product.discount || 0,
      discountType: this.product.discountType,
    }
    this.assignOverwriteVatInCart()
  },
  methods: {
    netPriceAfterCartDiscount,
    grossPrice,
    assignOverwriteVatInCart(value = this.overwriteVatInCart) {
      if (value !== null) this.productVat = value
      else if (this.product.overwriteVat !== null) this.productVat = this.product.overwriteVat
      else this.productVat = this.product.shopProduct.vat ?? 23
    },
    checkPermissions(...requiredPermissions) {
      const currentUser = this.$store.getters['auth/getCurrentUser']
      const user = typeof currentUser === 'string' ? JSON.parse(currentUser) : currentUser
      const userPermissions = user.rank.permissions

      return checkRanks(userPermissions, ...requiredPermissions)
    },
    changePrice(value) {
      this.$set(this.discount, 'discount', parseFloat(value))
      this.$emit('update-discount', this.discount)
    },
    changeSwitch() {
      this.$nextTick(() => {
        this.$emit('update-discount', this.discount)
      })
    },
    priceFormat(value) {
      return this.$options.filters.priceFormat(value)
    },
    pricesTooltip() {
      let html = '<div>'
      html += `
          <div>
          <div class="text-nowrap my-25">
            ${this.$i18n.t('offer.GrossPrice')}
            <div class="font-weight-bolder"> ${this.priceFormat(this.grossPrice(this.product))} ${this.settings.defaultCurrency || 'PLN'}</div>
          </div>
          <div class="text-nowrap my-25">
            ${this.$i18n.t('offer.NettoPrice')}
            <div class="font-weight-bolder"> ${this.priceFormat(this.netPriceAfterCartDiscount(this.product))} ${this.settings.defaultCurrency || 'PLN'}</div>
          </div>
        </div>
        </div>
      `
      return html
    },
  },
}
</script>

<style lang="scss">
.productBody { min-height: 4rem; overflow: hidden !important }
.shopCartItem {
  overflow: hidden !important;
}
.productLabel { margin-bottom: .14rem }

</style>
